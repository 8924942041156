<template>
  <v-app id="inspire">
    <v-main class="grey lighten-3 login">
      <v-container class="fill-height">
        <v-row align="center" align-content="center">
          <v-col align-self="center" class="d-flex justify-center">
            <v-card max-height="500" width="500" max-width="400" elevation="3">
              <v-card-title>
                <v-icon>
                  mdi-alert-circle
                </v-icon>
                404 : Not found
              </v-card-title>
              <v-card-text>
                <span class="subtitle-1">ขออภัยไม่พบหน้าที่คุณเรียกใช้งาน</span>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn to="/">
                  <v-icon>
                    mdi-home
                  </v-icon>
                  BACK TO HOME
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {}
</script>

<style scope>
.login {
  background-image: url('../../assets/login-bg1.jpg');
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
